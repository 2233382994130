






















































import { Component, Vue } from "vue-property-decorator";
import CardComponent from "@/commoncomponents/CardComponent.vue";
import { ScreenText } from "@/lang/ScreenText";
import paymentsModule from "@/store/modules/Payments/module";
import ToggleSwitch from "@/commoncomponents/ToggleSwitch.vue";
import APP_UTILITIES from "@/utilities/commonFunctions";

import {
  Discount,
  DiscountType,
  AmountUnitType,
  StateModalType,
  TimeRangeType,
} from "@/Model/payments/types";
import UIkit from "uikit";
import DiscountTableRow from "./DiscountTableRow.vue";
import StateModal from "@/popupcomponents/StateModal.vue";

@Component({
  components: {
    CardComponent,
    ToggleSwitch,
    DiscountTableRow,
    StateModal,
  },
})
export default class DiscountTable extends Vue {
  screenText = new ScreenText();
  discountType = DiscountType;
  discountUnitType = AmountUnitType;
  timeRangeType = TimeRangeType;
  isModalVisible = false;
  generateRandomIds = APP_UTILITIES.generateRandomAlphanumericId;
  IDS_LENGTH = 5;
  currentDiscount: Discount | null = null;

  modalConfig = {
    title: "Delete discount?",
    description:
      "You will permanently delete the selected discount rules and amount. You cannot undo this action.",
    type: StateModalType.ERROR,
    cancelButtonLabel: "cancel",
    confirmButtonLabel: "Delete discount",
  };

  onClose() {
    this.isModalVisible = false;
  }
  async onConfirm() {
    if (this.currentDiscount) {
      try {
        this.isModalVisible = false;
        await paymentsModule.deleteAutomaticDiscount(this.currentDiscount.id);
      } catch (error) {
        console.error("An error occurred while deleting the discount:", error);
      }
    }
  }

  onDelete(discount: any) {
    this.isModalVisible = true;
    this.currentDiscount = discount;
  }

  toggleAccordion(index: number) {
    UIkit.accordion(`#accordion-toggle-${index}`).toggle();
  }

  /**
   * Computes and returns a sorted list of discounts.
   *
   * The list is sorted by the discount type in descending order.
   *
   * @returns {Discount[]} The sorted array of discounts.
   */
  get discountListComputed(): Discount[] {
    return paymentsModule.discountList.sort(
      (a, b) => b.discountTypeId - a.discountTypeId
    );
  }
}
